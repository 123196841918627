<template>
  <div class="p-4 !container !h-80">
    <div class="flex justify-center flex-row items-center gap-x-2">
      <div>
        <h5 class="text-2xl">Account</h5>
        <Dropdown
          v-model="selectedCity1"
          :options="cities"
          option-label="name"
          option-value="code"
          placeholder="Select a City"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
