<template>
  <div>
    <TheHeader />
    <TheDrawer />
    <ConfirmDialog />
    <DynamicDialog />
    <ScrollTop />
    <Toast />

    <div class="w-[90%] mr-auto ml-auto mb-24">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style></style>
