<template>
  <div>
    <div class="p-2" @click="toggle">
      <i
        v-if="notifications.length > 0"
        id="notification-icon"
        ref="button"
        v-badge.warning
        size="small"
        class="pi pi-bell p-overlay-badge p-badge-lg !text-white"
        style="font-size: 1rem"
      ></i>
      <i
        v-else
        id="notification-icon"
        ref="button"
        size="small"
        class="pi pi-bell p-overlay-badge p-badge-lg !text-white"
        style="font-size: 1rem"
      ></i>
    </div>
  </div>
  <OverlayPanel
    ref="op"
    :pt="{
      root: { class: '!w-[500px] ' + showModalClass },
      content: { class: '!p-0' },
    }"
    @show="isLocked = true"
    @hide="isLocked = false"
  >
    <div class="flex justify-between items-center pr-4">
      <div class="text-lg font-bold p-4">Benachrichtungen</div>
      <i class="pi pi-trash" @click="notificationStore.dissmissNotifications()"> </i>
    </div>
    <VirtualScroller
      :items="notifications"
      :item-size="50"
      class="border-1 surface-border border-round flex !w-full items-center !h-[200px]"
      :pt="{
        content: {
          class: '!divide-y-2 !divide-slate-200 !w-full',
        },
      }"
    >
      <template #item="{ item }">
        <div
          class="flex items-center px-4 justify-between flex-wrap cursor-pointer"
          @click="toggleNotification(item)"
        >
          <div class="my-2 flex items-center">{{ item.title }}</div>
          <div>{{ useDateFormat(item.created_at, "DD.MM.YYYY").value }}</div>
        </div>
      </template>
    </VirtualScroller>
  </OverlayPanel>
  <Dialog
    v-model:visible="showNotificationModal"
    modal
    :header="currentNotification?.title"
    :style="{ width: '50vw' }"
  >
    <p>
      {{ currentNotification?.message }}
    </p>
    <NuxtLink
      :to="{
        name: 'budget-manager-id',
        params: { id: currentNotification?.product_group?.product_group_number },
        query: currentNotification?.budget_id
          ? {
              budgetId: currentNotification?.budget_id,
            }
          : {},
      }"
      @click="showNotificationModal = false"
    >
      <Button class="!mt-4"> Zum Budget</Button>
    </NuxtLink>
  </Dialog>
</template>

<script setup lang="ts">
import OverlayPanel from "primevue/overlaypanel";
const op = ref();
const button = ref();
const notificationStore = useNotificationStore();
const showModalClass = ref();
const showNotificationModal = ref(false);
const currentNotification = ref<(typeof notifications.value)[0]>();
const isLocked = useScrollLock(document.body);
const toggleNotification = (item: (typeof notifications.value)[0]) => {
  showNotificationModal.value = !showNotificationModal.value;
  currentNotification.value = item;
};

const { notifications } = storeToRefs(notificationStore);

const toggle = (event) => {
  op.value.toggle(event);
};
</script>

<style scoped></style>
