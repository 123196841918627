<template>
  <header class="sticky top-0 z-[1000] shadow-md">
    <div :class="`flex h-full  items-center ${bgGradient}`">
      <div class="mx-4 mr-auto flex items-center">
        <Button
          icon="pi pi-align-justify"
          icon-class="text-[#f6f6f6]"
          class="p-button-rounded p-button-text p-button-plain"
          data-testid="drawer-toggle"
          @click="toggleDrawer"
        />
        <span v-if="config.public.PREVIEW_MODE" class="text-xl font-bold text-white"
          >DEV-
        </span>
        <span class="text-xl font-bold mr-4 text-white"> EWO Ads Manager</span>
      </div>
      <div class="flex justify-center items-center mr-24 gap-4">
        <SplitButton
          data-testid="shop-dropdown"
          :label="selectedShopName"
          :model="shopItems"
          value
          class="p-button-text shop-dropdown"
        ></SplitButton>
        <DevOnly>
          <NotifcationCenter />
        </DevOnly>
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
const store = useAppStore();

const config = useRuntimeConfig();

const toggleDrawer = () => {
  store.showDrawer = !store.showDrawer;
};

const selectedShopName = computed(() => {
  return store.selectedShop?.name?.toUpperCase();
});

const shopItems = computed(() => {
  return store.shops.map((shop) => {
    return {
      label: shop?.name?.toUpperCase(),
      command: () => {
        store.selectedShop = shop;
        window.location.reload();
      },
    };
  });
});

const bgGradient = computed(() => {
  switch (store.selectedShop.name) {
    case "ofenseite": {
      return "bg-gradient-ofenseite ";
    }
    case "herrenseite": {
      return "bg-gradient-herrenseite ";
    }
  }
});
</script>
<style lang="scss">
.shop-dropdown {
  .p-button-label,
  .p-icon.p-button-icon {
    color: #f6f6f6 !important;
  }
}
</style>
