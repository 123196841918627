<template>
  <SideBar
    v-model:visible="store.showDrawer"
    class="shadow-inner !w-[400px]"
    style="'overflow': 'scroll'"
  >
    <template #header>
      <div class="flex items-center justify-start">
        <div class="text-gradient text-2xl font-extrabold text-transparent">
          AdsManager EWO
        </div>
      </div>
    </template>
    <PanelMenu v-model:expandedKeys="store.openTabs" class="" :model="store.menus" />
    <div class="mt-auto border-t-2 bg-slate-50 p-4 shadow-inner">
      <div class="flex items-center justify-between">
        <Avatar label="A" class="mr-2" size="xlarge" />
        <div class="text-lg">{{ data.user?.user_metadata.username }}</div>
        <Button
          icon="pi pi-cog"
          class="p-button-rounded p-button-text p-button-plain p-button-lg"
          @click="openSettings"
        ></Button>
      </div>

      <!-- USER -->
    </div>
  </SideBar>
</template>

<script setup lang="ts">
import { useDialog } from "primevue/usedialog";
import SettingsOverlay from "./SettingsOverlay.vue";

const store = useAppStore();
const { data } = await useSupabaseClient().auth.getUser();

const dialog = useDialog();

const openSettings = () => {
  store.showDrawer = false;
  dialog.open(SettingsOverlay, {
    props: {
      header: `Settings`,

      modal: true,

      style: {
        width: "50vw",
        height: "fit",
      },
    },
  });
};
</script>

<style>
.p-sidebar-content {
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

.p-sidebar-header {
  justify-content: space-between;
}
</style>
